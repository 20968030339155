import {
  Box,
  Button,
  Center,
  HStack,
  Text,
  VStack,
  Link,
} from "@chakra-ui/react";
import { CgMail } from "react-icons/cg";
import { AiFillGithub, AiFillLinkedin } from "react-icons/ai";

export default function Footer() {
  return (
    <Box mt="75px" pb="20px">
      <Center>
        <VStack>
          <HStack>
            <Link href="mailto: bobbylaudeman@gmail.com">
              <Button p="0" variant="ghost">
                <CgMail size={25} />
              </Button>
            </Link>
            <Link href="https://www.linkedin.com/in/bobby-laudeman-59704b198">
              <Button p="0" variant="ghost">
                <AiFillLinkedin size={25} />
              </Button>
            </Link>
            <Link href="https://github.com/Laudeman">
              <Button p="0" variant="ghost">
                <AiFillGithub size={25} />
              </Button>
            </Link>
          </HStack>
          <Text>© BOBBY LAUDEMAN</Text>
        </VStack>
      </Center>
    </Box>
  );
}
