import { Canvas, useFrame } from "@react-three/fiber";
import React, { useRef } from "react";
import { Group } from "three";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export default function LaptopModel() {
	return (
		<center>
			<Canvas orthographic camera={{ zoom: 50, position: [0, 10, 100] }}>
				<ambientLight intensity={0.1} />
				<directionalLight color="white" position={[0, 2, 0]} />
				<Laptop />
			</Canvas>
		</center>
	);
}

function Laptop() {
	const ref = useRef<Group>(null);
	let meshRef = ref.current;
	const model = useLoader(GLTFLoader, "./laptop/scene.gltf");
	useFrame((state) => {
		const t = state.clock.getElapsedTime();
		if (meshRef) {
			meshRef.rotation.set(0, Math.cos((Math.PI / 4) * t * 0.4), 0);
			//meshRef.position.y = (0.5 + Math.cos(t / 2)) / 7;
		} else {
			meshRef = ref.current;
		}
	});
	return (
		<group ref={ref}>
			<mesh>
				<primitive
					object={model.scene}
					scale={1}
					position={[0, -1, 0]}
				></primitive>
			</mesh>
		</group>
	);
}
