import React from "react";
import {
	Link,
	Box,
	Flex,
	Stack,
	FlexProps,
	useColorMode,
	IconButton,
	BoxProps,
	Button,
	ButtonProps,
	useOutsideClick,
} from "@chakra-ui/react";
import { CgBulb, CgClose, CgMenu } from "react-icons/cg";

import Logo from "./Logo";

export default function NavBar(props: FlexProps) {
	const [isOpen, setIsOpen] = React.useState(false);
	const toggleHandler = () => {
		setIsOpen(!isOpen);
	};
	const navMenuRef = React.useRef(null);
	useOutsideClick({ ref: navMenuRef, handler: () => setIsOpen(false) });
	return (
		<Box ref={navMenuRef}>
			<NavBarContainer flexProps={{ ...props }}>
				<Link href="#about">
					<Logo h={props.h} />
				</Link>
				<MenuToggle toggle={toggleHandler} isOpen={isOpen} />
				<MenuLinks
					boxProps={{ bgColor: props.bgColor }}
					isOpen={isOpen}
				/>
			</NavBarContainer>
			<Box id="Spacer" h={props.h} mb="30px" />
		</Box>
	);
}

const MenuToggle = ({
	isOpen,
	toggle,
}: {
	isOpen: boolean;
	toggle?: React.MouseEventHandler<HTMLButtonElement>;
}) => {
	return (
		<Button
			variant="ghost"
			display={{ base: "block", md: "none" }}
			onClick={toggle}
			mx={2}
		>
			{isOpen ? <CgClose /> : <CgMenu />}
		</Button>
	);
};

const MenuItem = ({
	to = "/",
	buttonProps,
	children,
}: {
	to: string;
	buttonProps?: ButtonProps;
	children?: React.ReactNode;
}) => {
	return (
		<Link w="100%" href={to}>
			<Button w="100%" variant="ghost" {...buttonProps}>
				{children}
			</Button>
		</Link>
	);
};

const MenuLinks = ({
	isOpen = false,
	boxProps,
}: {
	isOpen: boolean;
	boxProps: BoxProps;
}) => {
	const { toggleColorMode } = useColorMode();
	return (
		<Box
			{...boxProps}
			display={{ base: isOpen ? "block" : "none", md: "block" }}
			flexBasis={{ base: "100%", md: "auto" }}
		>
			<Stack
				px={[2, 2, 2, 2]}
				justify={[
					"flex-start",
					"space-between",
					"flex-end",
					"flex-end",
				]}
				direction={["column", "column", "row", "row"]}
				pt={["4px", "4px", 0, 0]}
			>
				<MenuItem to="#about">About Me</MenuItem>
				<MenuItem to="#projects">Projects</MenuItem>
				<MenuItem to="#contact">Contact Me</MenuItem>
				<MenuItem to="https://docs.google.com/document/d/1xTp3AC3m7EkkPtnEgALEdnGcqtd3I4lg4QTmEmdg_h4/export?format=pdf">
					Resume
				</MenuItem>
				<IconButton
					aria-label="Toggle Color Mode"
					icon={<CgBulb />}
					onClick={toggleColorMode}
					variant="ghost"
				/>
			</Stack>
		</Box>
	);
};

const NavBarContainer = ({
	flexProps,
	children,
}: {
	flexProps: FlexProps;
	children?: React.ReactNode;
}) => {
	return (
		<Flex
			id="NavBarContainer"
			as="nav"
			align="center"
			boxShadow="lg"
			justify="space-between"
			wrap="wrap"
			{...flexProps}
		>
			{children}
		</Flex>
	);
};
