import { Container } from "@chakra-ui/react";
import About from "../components/About";
import Contact from "../components/Contact";
import Projects from "../components/Projects";

export default function Info() {
  return (
    <Container maxW="container.xl" centerContent>
      <About />
      <Projects />
      <Contact />
    </Container>
  );
}
