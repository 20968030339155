import { Box, Button, Center, Tooltip, Heading, Link } from "@chakra-ui/react";
import { useState } from "react";

export default function Contact() {
  const sendToClipboard = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy);
    setToolTipText("Copied!");
  };
  const [toolTipText, setToolTipText] = useState("Click to copy");
  return (
    <Box id="contact" pt="40px">
      <Heading textAlign="center" size="2xl">
        Contact Me
      </Heading>
      <Center py="1.5em">
        <Tooltip
          id="Email Tooltip"
          onClose={() => {
            setToolTipText("Click to copy");
          }}
          closeOnClick={false}
          placement="bottom"
          hasArrow
          label={toolTipText}
        >
          <Button
            onClick={() => {
              sendToClipboard("bobbylaudeman@gmail.com");
            }}
          >
            bobbylaudeman@gmail.com
          </Button>
        </Tooltip>
      </Center>
      <Center>
        <Tooltip label="Download Resume" placement="bottom" hasArrow>
          <Link href="https://docs.google.com/document/d/1xTp3AC3m7EkkPtnEgALEdnGcqtd3I4lg4QTmEmdg_h4/export?format=pdf">
            <Button>Resume</Button>
          </Link>
        </Tooltip>
      </Center>
    </Box>
  );
}
