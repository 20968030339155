import {
	AspectRatio,
	Badge,
	Box,
	Center,
	Heading,
	VStack,
	HStack,
	Image,
	ListItem,
	SimpleGrid,
	Text,
	UnorderedList,
	RadioGroup,
	Radio,
} from "@chakra-ui/react";
import ProjectInfo from "../json/Projects.json";
import { useState } from "react";

interface ProjectType {
	name: string;
	media: {
		isVideo: boolean;
		link: string;
	}[];
	technologies: string[];
	about: string;
	contributions: string[];
}

export default function Projects() {
	return (
		<Box id="projects" width="100%" pt="50px">
			<Heading textAlign="center" size="2xl" mb="8">
				Projects
			</Heading>
			<SimpleGrid columns={[1]} spacing={10}>
				{ProjectInfo.projectList.map(function (
					project: ProjectType,
					index: Number
				) {
					return (
						<Project project={project} key={project.name + index} />
					);
				})}
			</SimpleGrid>
		</Box>
	);
}

function Project({ project }: { project: ProjectType }) {
	return (
		<Box borderTop="1px" pt={5}>
			<Heading textAlign="left" size={["xl"]} mb={4}>
				{project.name}
			</Heading>
			{project.media ? <ProjectMedia project={project} /> : null}
			<Heading size="lg" my={3}>
				About:
			</Heading>
			<Text>{project.about}</Text>
			<Heading size="lg" my={3}>
				Contributions:
			</Heading>
			<UnorderedList>
				{project.contributions.map(function (
					contribution: string,
					index: Number
				) {
					return (
						<ListItem key={contribution + index}>
							{contribution}
						</ListItem>
					);
				})}
			</UnorderedList>
		</Box>
	);
}

function ProjectMedia({ project }: { project: ProjectType }) {
	const [mediaIndex, setMediaIndex] = useState(0);
	return (
		<Box>
			{project.media[mediaIndex].isVideo ? (
				<AspectRatio width="100%" ratio={16 / 9}>
					<iframe
						title={project.name + " Video"}
						src={project.media[mediaIndex].link}
					/>
				</AspectRatio>
			) : (
				<Center>
					<Image
						fit="scale-down"
						title={project.name + "Image"}
						src={
							process.env.PUBLIC_URL +
							project.media[mediaIndex].link
						}
					/>
				</Center>
			)}
			<Center>
				<VStack>
					<HStack mt={2}>
						{project.technologies.map(function (
							technology: string,
							index: Number
						) {
							return (
								<Badge key={technology + index}>
									{technology}
								</Badge>
							);
						})}
					</HStack>
					<RadioGroup
						onChange={(event) => {
							const choiceID = Number(event);
							setMediaIndex(choiceID);
						}}
						value={mediaIndex.toString()}
					>
						<HStack>
							{project.media.length > 1 ? (
								project.media.map(function (
									value: { isVideo: boolean; link: string },
									index: number
								) {
									return (
										<Radio
											key={index}
											value={index.toString()}
										/>
									);
								})
							) : (
								<></>
							)}
						</HStack>
					</RadioGroup>
				</VStack>
			</Center>
		</Box>
	);
}
