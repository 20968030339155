import { Box, Text, Heading, Link } from "@chakra-ui/react";

export default function About() {
	return (
		<Box id="about">
			<Heading size="2xl" textAlign="center" mb="10">
				Software Engineer / Developer
			</Heading>
			<Text fontSize={["1em", "1.5em"]}>
				Hello! My name is Bobby and I'm the Lead XR Programmer at
				California State University, San Bernardino. I am currently
				developing multiple VR experiences within the Unity and Unreal
				game engines. These applications are being implemented within
				classrooms in order to provide students a better medium for
				understanding the material.
			</Text>
			<Text mt={30} fontSize={["1em", "1.5em"]}>
				I am very passionate about technology and learning different
				ways to create experiences using it. I am always looking to
				further expand my understanding and skills as a developer.
				Please{" "}
				<Link textDecoration="underline" href="#contact">
					contact me
				</Link>{" "}
				if you have any helpful advice or would like to collaborate on
				any projects!
			</Text>
		</Box>
	);
}
