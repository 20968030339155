import NavBar from "./components/NavBar";
// import { Route, Routes } from "react-router-dom";
import { Box, useColorModeValue } from "@chakra-ui/react";
import Info from "./pages/Info";
import LaptopModel from "./components/LaptopModel";
import Footer from "./components/Footer";

function App() {
	return (
		<Box>
			<header>
				<NavBar
					id="NavBar"
					zIndex={200}
					position="fixed"
					// backdropFilter="blur(15px)"
					bgColor={useColorModeValue("gray.300", "gray.700")}
					h="50px"
					w="100%"
				/>
			</header>
			{/* <Routes>
          <Route path="/" element={<div />} />
          <Route path="/about" element={<About />} />
        </Routes> */}
			<LaptopModel />
			<Info />
			<Footer />
		</Box>
	);
}

export default App;
