import { Center, Button, CenterProps } from "@chakra-ui/react";

export default function Logo(props: CenterProps) {
  return (
    <Center {...props}>
      <Button variant="ghost" fontSize="2xl" fontWeight={"bold"}>
        {/* &lt; Bobby Laudeman /&gt; */}
        Bobby Laudeman
      </Button>
    </Center>
  );
}
